<template>
    <div>
        <cosecha-urgente></cosecha-urgente>
        <screenshot name-pdf="Cosecha"></screenshot>
        <b-card>
            <b-card-title>
                <b-row class="mb-1">
                    <b-col>
                        <h1>Cosecha</h1>
                    </b-col>
                </b-row>
            </b-card-title>
            <b-card-body>
                <b-form-group label-cols="4" label-cols-lg="3" label="Capacidad máxima de cosecha" label-for="basicInput">
                    <b-input-group append="piezas/día." class="input-group-merge">
                        <b-form-input id="basicInput" placeholder="" value="80000" readonly/>
                    </b-input-group>
                </b-form-group>
                <br>
                <b-form-group label-cols="4" label-cols-lg="3" label="Capacidad de acopio" label-for="basicInput">
                    <b-input-group append="ton(14 jaulas de 110 ton c/u)." class="input-group-merge">
                        <b-form-input id="basicInput" placeholder="" value="1540" readonly/>
                    </b-input-group>
                </b-form-group>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import CosechaUrgente from "@/views/clients/blumar/components/CosechaUrgente";
import Screenshot from "@/views/clients/blumar/components/Screenshot";

import {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BCardTitle
} from "bootstrap-vue";

export default {
    name: "cosecha",

    components: {
        BRow,
        BCol,
        BButton,
        BCard,
        BCardBody,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BCardTitle,

        CosechaUrgente,
        Screenshot
    }
};
</script>
<style lang="scss" scoped>
.alinearTextVertical {
    line-height: 2.5em !important;
}
</style>
